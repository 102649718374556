import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMPCutsoff } from '../../models/IMPCutsoff';

interface MPCutsoffState {
  cutoff: IMPCutsoff;
  isLoading: boolean;
  error: string;
}

const initialState = {
  cutoff: {} as IMPCutsoff,
  isLoading: false,
  error: '',
}

export const mpCutsoffSlice = createSlice({
  name: 'MPCutsoff',
  initialState,
  reducers: {
    mpCutsoffFetching(state) {
      state.isLoading = true;
    },
    mpCutsoffFetchingSuccess(state, action: PayloadAction<IMPCutsoff>) {
      state.isLoading = false;
      state.cutoff = action.payload;
      state.error = '';
    },
    mpCutsoffFetchingError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  }
});

export default mpCutsoffSlice.reducer;