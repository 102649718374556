import '../App.css';
import Header from '../components/Header';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { useEffect } from 'react';
import { fetchMpCutsoff, fetchToken } from '../store/reducers/ActionReducer';
import { Button } from '../@/components/ui/button';

const IndexPage =() => {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector(state => state.tokenReducer);
  const { cutoff } = useAppSelector(state => state.mpCutsoffReducer)

  useEffect(() => {
    dispatch(fetchToken());
    dispatch(fetchMpCutsoff());
  }, []);

  return (<>
        <div className='flex flex-row justify-center'>
          <img src='/android-chrome-512x512.png' />
        </div>
        <div className='flex flex-row justify-center'>
          <div className='mx-10'>
            <h6>Токен</h6> <p>{ token.price }</p>
          </div>
          <div className='mx-10'>
            <h6>M+ Cutsoff</h6> <p>{ cutoff.cutoff }</p>
          </div>  
        </div>
      </>);
}

export default IndexPage;