import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import BnetAuth from './components/BnetAuth';
import IndexPage from './pages';
import LoginForm from './pages/Login';
import RegistrationForm from './pages/Registration';
import { Provider } from 'react-redux';
import { setupStore } from './store/store';

import { fetchProfile } from './store/reducers/ActionReducer';
import { useAppDispatch } from './hooks/redux';
import ProfilePage from './pages/Profile';

const router = createBrowserRouter([
  {
    path: '/',
    element: <IndexPage/>,
  },
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const store = setupStore();

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
