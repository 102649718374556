import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBnetToken } from '../../models/IBnetToken';

interface TokenState {
  token: IBnetToken;
  isLoading: boolean;
  error: string;
}

const initialState = {
  token: {} as IBnetToken,
  isLoading: false,
  error: '',
}

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    tokenFetching(state) {
      state.isLoading = true;
    },
    tokenFetchingSuccess(state, action: PayloadAction<IBnetToken>) {
      state.isLoading = false;
      state.token = action.payload;
      state.error = '';
    },
    tokenFetchingError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  }
});

export default tokenSlice.reducer;