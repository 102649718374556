import { IBnetToken } from '../../models/IBnetToken';
import { IMPCutsoff } from '../../models/IMPCutsoff';
import { AppDispatch } from "../store";
import { tokenSlice } from './TokenSlice';
import { mpCutsoffSlice } from './MCutsoffSlice';
import { profileSlice } from './ProfileSlice';
import { authSlice } from './AuthSlice';
import axios from 'axios';
import { IBnetAccount } from '../../models/IBnetAccount';
import { IAuthToken } from '../../models/IAuthToken';
import { LoginDTO } from '../../dto/login.dto';
import { RegistrationDTO } from '../../dto/reistration.dto';
import { charactersSlice } from './CharactersSlice';

//axios.defaults.baseURL = 'http://localhost:8002/V1';
axios.defaults.baseURL = 'https://api.outlawraccoon.ru/V1';

if (localStorage.getItem('token')) {
  axios.defaults.headers.common = {
    'Authorization': `Bearer ${localStorage.getItem('token')}`
  };
}

export const fetchToken =() => async (dispatch: AppDispatch) => {
  try {
    dispatch(tokenSlice.actions.tokenFetching());
    const data = await axios.get<IBnetToken>('/token/current');
    dispatch(tokenSlice.actions.tokenFetchingSuccess(data.data));
  } catch (e: any) {
    dispatch(tokenSlice.actions.tokenFetchingError(e.message));
  }
}

export const fetchMpCutsoff =() => async (dispatch: AppDispatch) => {
  try {
    dispatch(mpCutsoffSlice.actions.mpCutsoffFetching());
    const data = await axios.get<IMPCutsoff>('/rio/cutoff');
    dispatch(mpCutsoffSlice.actions.mpCutsoffFetchingSuccess(data.data));
  } catch (e: any) {
    dispatch(mpCutsoffSlice.actions.mpCutsoffFetchingError(e.message));
  }
}

export const fetchProfile =() => async (dispatch: AppDispatch) => {
  try {
    dispatch(profileSlice.actions.profileFetching());
    const data = await axios.get<IBnetAccount>('/accounts/me');
    dispatch(profileSlice.actions.profileFetchingSuccess(data.data));
  } catch (e: any) {
    dispatch(profileSlice.actions.profileFetchingError(e.message));
  }
}

export const loginAction =(data: LoginDTO) => async (dispatch: AppDispatch) => {
  try {
    dispatch(authSlice.actions.login());
    const result = await axios.post<IAuthToken>('/auth/login', data);
    dispatch(authSlice.actions.loginSuccess(result.data));
  } catch (e: any) {
    dispatch(authSlice.actions.loginError(e.message));
  }
}

export const logoutAction =() => async (dispatch: AppDispatch) => {
  try {
    dispatch(authSlice.actions.logout());
    dispatch(profileSlice.actions.profileClear());
  } catch (e: any) {
  }
}

export const registration =(dto: RegistrationDTO) => async (dispatch: AppDispatch) => {
  try {
    dispatch(authSlice.actions.registration());
    const data = await axios.post<IAuthToken>('/auth/registration', dto);
    dispatch(authSlice.actions.registrationSuccess(data.data));
  } catch (e: any) {
    dispatch(authSlice.actions.registrationError(e.message));
  }
}

export const fetchCharacters =(code: string = '') => async (dispatch: AppDispatch) => {
  try {
    dispatch(charactersSlice.actions.charactersFetching());
    const url = code ? `/characters?code=${code}` : '/characters'
    const data = await axios.get(url);
    console.log(data);
    dispatch(charactersSlice.actions.charactersFetchingSuccess(data.data.characters));
  } catch (e: any) {
    dispatch(charactersSlice.actions.charactersFetchingError(e.message));
  }
}
