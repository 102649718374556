import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICharacter } from 'src/models/ICharacter';

interface CharactersState {
  characters: ICharacter[];
  isLoading: boolean;
  error: string;
}

const initialState = {
  characters: [] as ICharacter[],
  isLoading: false,
  error: '',
}

export const charactersSlice = createSlice({
  name: 'characters',
  initialState,
  reducers: {
    charactersFetching(state) {
      state.isLoading = true;
    },
    charactersFetchingSuccess(state, action: PayloadAction<ICharacter[]>) {
      state.isLoading = false;
      state.characters = action.payload;
      state.error = '';
    },
    charactersFetchingError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  }
});

export default charactersSlice.reducer;