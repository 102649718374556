import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuthToken } from '../../models/IAuthToken';
import { useAppDispatch } from '../../hooks/redux';
import { fetchProfile } from './ActionReducer';

const initialState = {
  token: {} as IAuthToken ,
  isLoading: false,
  error: '',
  isAuth: !!localStorage.getItem('token')
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state) {
      state.isLoading = true;
    },
    loginSuccess(state, action: PayloadAction<IAuthToken>) {
      state.isLoading = false;
      state.token = action.payload;
      state.error = '';
      state.isAuth = true;
      localStorage.setItem('token', action.payload.access_token);
    },
    loginError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
      state.isAuth = false;
    },
    registration(state) {
      state.isLoading = true;
    },
    registrationSuccess(state, action: PayloadAction<IAuthToken>) {
      state.isLoading = false;
      state.token = action.payload;
      state.error = '';
      state.isAuth = true;
    },
    registrationError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
      state.isAuth = false;
    },
    logout(state) {
      state.isAuth = false;
      state.error = '';
      state.token = {} as IAuthToken;
      localStorage.removeItem('token');
    }
  }
});



export default authSlice.reducer;