import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBnetAccount } from '../../models/IBnetAccount';

interface ProfileState {
  profile: IBnetAccount;
  isLoading: boolean;
  error: string;
}

const initialState = {
  profile: (localStorage.getItem('profile')) ? JSON.parse(localStorage.getItem('profile') || '') :  {} as IBnetAccount,
  isLoading: false,
  error: '',
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    profileFetching(state) {
      state.isLoading = true;
    },
    profileFetchingSuccess(state, action: PayloadAction<IBnetAccount>) {
      state.isLoading = false;
      state.profile = action.payload;
      state.error = '';
      localStorage.setItem('profile', JSON.stringify(action.payload));
    },
    profileFetchingError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    profileClear(state) {
      localStorage.removeItem('profile')
      state = initialState;
    }
  }
});

export default profileSlice.reducer;